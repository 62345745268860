import React from "react";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import Login from "./components/Home/Login";
import Main from "./components/Main/Main";
import Patient from "./components/Patient/Patient";
import PatientDetail from "./components/Patient/PatientDetail";
import Dysphagia from "./components/Patient/Dysphagia";
import DysphagiaInfo from "./components/Patient/DysphagiaDetail";
import { AdminDataProvider } from "./lib/service/AdminDataProvider";
import { Button, Popup, TextBox } from "devextreme-react";
import tBox from "devextreme/ui/text_box";
import LicenceList from "./components/LincenceList/LicenceList";
import Alert from "./components/Etc/Alert";
import ErrorPage from "./components/Etc/ErrorPage";
import Assessment from "./components/Webview/Assessment";
import Education from "./components/Webview/Education";
import { lang } from "./lib/utils/language";
import { locale, loadMessages, formatMessage } from "devextreme/localization";
loadMessages(lang);
const start = async () => {
  locale("" + localStorage.getItem("lang"));
};

interface AppProps {
  // Define your props interface here if needed
}

interface AppState {
  isPopupVisible: boolean;
  isPopupVisible2: boolean;
  isPopupVisible3: boolean;
}

const _dev = (e: any, d: any) => {
  const id = document.getElementById(e) as HTMLElement;
  return d.getInstance(id);
};

const logout = async () => {
  const adminService = new AdminDataProvider();
  await adminService.logout(localStorage.getItem("id")).then((d: any) => {
    if (d.status !== 200) {
      Alert("Logout Fail");
    } else {
      console.log(d);
      sessionStorage.removeItem("name");
      sessionStorage.removeItem("id");
      sessionStorage.clear();
      window.location.href = "/auth/login";
    }
  });
};

start();

class App extends React.Component<AppProps, AppState> {
  constructor(props: AppProps) {
    super(props);

    this.state = {
      isPopupVisible: false,
      isPopupVisible2: false,
      isPopupVisible3: false,
    };

    this.showPopup = this.showPopup.bind(this);
    this.hidePopup = this.hidePopup.bind(this);
    this.showPopup2 = this.showPopup2.bind(this);
    this.hidePopup2 = this.hidePopup2.bind(this);
    this.showPopup3 = this.showPopup3.bind(this);
    this.hidePopup3 = this.hidePopup3.bind(this);
  }

  pwChange = async () => {
    const pw = _dev("pw", tBox).option("value");
    const newpw = _dev("newpw", tBox).option("value");
    const adminService = new AdminDataProvider();
    await adminService
      .PassWordChange(localStorage.getItem("id"), pw, newpw)
      .then((d: any) => {
        if (d.data.statusCode !== 200) {
          console.log(d);
          this.hidePopup2();
          this.showPopup3();
        } else {
          this.hidePopup();
          this.hidePopup2();
          this.hidePopup3();
        }
      });
  };

  renderContent = () => {
    return (
      <div>
        <p>Password</p>
        <TextBox id="pw" placeholder="PASSWORD" className="inp"></TextBox>
        <p style={{ marginTop: "15px" }}>New Password</p>
        <TextBox id="newpw" placeholder="NEW PASSWORD" className="inp" />
        <div
          className="w-100"
          style={{ textAlign: "center", marginTop: "15px" }}
        >
          <Button
            type="default"
            width={150}
            height={40}
            onClick={() => this.showPopup2()}
          >
            Save changes
          </Button>
          <Button
            style={{ marginLeft: "15px" }}
            width={150}
            height={40}
            onClick={() => this.hidePopup()}
          >
            Close
          </Button>
        </div>
      </div>
    );
  };

  renderContent2 = () => {
    return (
      <div>
        <p>Do you really want chnage about Password?</p>

        <div
          className="w-100"
          style={{ textAlign: "center", marginTop: "30px" }}
        >
          <Button
            type="normal"
            width={90}
            height={40}
            onClick={() => this.pwChange()}
          >
            Yes
          </Button>
          <Button
            style={{ marginLeft: "15px" }}
            type="normal"
            width={90}
            height={40}
            onClick={() => this.hidePopup2()}
          >
            No
          </Button>
        </div>
      </div>
    );
  };

  renderContent3 = () => {
    return (
      <div>
        <p>Please confirm and enter again</p>

        <div
          className="w-100"
          style={{ textAlign: "center", marginTop: "30px" }}
        >
          <Button
            type="normal"
            width={90}
            height={40}
            onClick={() => this.hidePopup3()}
          >
            Yes
          </Button>
        </div>
      </div>
    );
  };

  showPopup() {
    this.setState({
      isPopupVisible: true,
    });
  }

  hidePopup() {
    this.setState({
      isPopupVisible: false,
    });
  }

  showPopup2() {
    this.setState({
      isPopupVisible2: true,
    });
  }

  hidePopup2() {
    this.setState({
      isPopupVisible2: false,
    });
  }

  showPopup3() {
    this.setState({
      isPopupVisible3: true,
    });
  }

  hidePopup3() {
    this.setState({
      isPopupVisible3: false,
    });
  }

  render() {
    return (
      <Router>
        <Switch>
          <Route path="/auth/login" component={Login} />

          <Redirect from="/" exact to="/auth/login" />
          <Route path="/licenceList" component={LicenceList} />
          <Route path="/assessment" component={Assessment} />
          <Route path="/education" component={Education} />

          <div className="comHeight">
            <Popup
              width={500}
              height={280}
              title="Password Change"
              visible={this.state.isPopupVisible}
              contentRender={this.renderContent}
              onHiding={this.hidePopup}
            />
            <Popup
              width={350}
              height={200}
              title="Confirm changes"
              visible={this.state.isPopupVisible2}
              contentRender={this.renderContent2}
              onHiding={this.hidePopup2}
            />

            <Popup
              width={250}
              height={200}
              title="Notice"
              visible={this.state.isPopupVisible3}
              contentRender={this.renderContent3}
              onHiding={this.hidePopup3}
            />
            <div className="skip-navi">
              <a href="#content">본문 바로가기</a>
            </div>
            <div className="wrap">
              <header className="header">
                <div className="header__wrap">
                  <h1>
                    <a href="/main">
                      <span className="blind">Hicare</span>
                    </a>
                  </h1>

                  <div className="util">
                    <div className="icon-wrap">
                      <i
                        className="fa fa-user-o icon-img"
                        aria-hidden="true"
                      ></i>
                    </div>
                    <strong className="user-name">
                      <div id="zzid"> {sessionStorage.getItem("id")}</div>
                      {/* <div id="zzid">Admin</div> */}
                    </strong>
                    <button id="btnMenu" type="button" className="menu-all">
                      <i
                        className="fa fa-angle-down icon-arrow"
                        aria-hidden="true"
                      ></i>
                    </button>
                  </div>
                </div>

                <div className="gnb-wrap">
                  <nav className="gnb">
                    <ul className="depth01">
                      {/* <li>
                        <a href="/main">
                          <i className="fa fa-home" aria-hidden="true"></i>
                          <span>{formatMessage("txt_home")}</span>
                        </a>
                      </li>
                      <li>
                        <p>
                          <i
                            className="fa fa-angle-down"
                            aria-hidden="true"
                          ></i>
                          <span>{formatMessage("txt_list_register")}</span>
                        </p>
                        <ul
                          className="depth02"
                          style={{
                            display:
                              localStorage.getItem("id") === "system"
                                ? "none"
                                : "",
                          }}
                        >
                          <li>
                            <a href="/patient">
                              <i className="fa fa-user" aria-hidden="true"></i>
                              <span>{formatMessage("txt_patient")}</span>
                            </a>
                          </li>
                          <li>
                            <a href="/device">
                              <i
                                className="fa fa-desktop"
                                aria-hidden="true"
                              ></i>
                              <span>{formatMessage("txt_device")}</span>
                            </a>
                          </li>
                        </ul>
                      </li>
                      <li>
                        <p>
                          <i
                            className="fa fa-angle-down"
                            aria-hidden="true"
                          ></i>
                          <span>{formatMessage("txt_links")}</span>
                        </p>
                        <ul
                          className="depth02"
                          style={{
                            display:
                              localStorage.getItem("id") === "system"
                                ? "none"
                                : "",
                          }}
                        >
                          <li>
                            <a href="/deviceLink">
                              <i
                                className="fa fa-retweet"
                                aria-hidden="true"
                              ></i>
                              <span>{formatMessage("txt_patient_device")}</span>
                            </a>
                          </li>
                        </ul>
                      </li>
                      <li>
                        <p>
                          <i
                            className="fa fa-angle-down"
                            aria-hidden="true"
                          ></i>
                          <span>{formatMessage("txt_history")}</span>
                        </p>
                        <ul
                          className="depth02"
                          style={{
                            display:
                              localStorage.getItem("id") === "system"
                                ? "none"
                                : "",
                          }}
                        >
                        </ul>
                      </li>
                      <li
                        style={{
                          display:
                            localStorage.getItem("id") === "system"
                              ? "none"
                              : "",
                        }}
                      >
                        <p>
                          <i
                            className="fa fa-angle-down"
                            aria-hidden="true"
                          ></i>
                          <span>{formatMessage("txt_settings")}</span>
                        </p>
                        <ul className="depth02">
                          <li
                            style={{
                              display:
                                localStorage.getItem("id") === "system"
                                  ? "none"
                                  : "",
                            }}
                          >
                            <a href="/alert">
                              <i className="fa fa-list" aria-hidden="true"></i>
                              <span>{formatMessage("txt_alert_level")}</span>
                            </a>
                          </li>
                        </ul>
                      </li> */}
                      <li>
                        <div
                          style={{ cursor: "pointer" }}
                          onClick={() => this.showPopup()}
                        >
                          <i className="fa fa-lock" aria-hidden="true"></i>
                          <span>Password Change</span>
                        </div>
                      </li>
                      <li>
                        <div
                          style={{ cursor: "pointer" }}
                          onClick={() => logout()}
                        >
                          <i className="fa fa-sign-out" aria-hidden="true"></i>
                          <span>{formatMessage("txt_logout")}</span>
                        </div>
                      </li>
                    </ul>
                  </nav>
                </div>
              </header>

              <Route path="/main" component={Main} />
              <Route path="/patient" component={Patient} />
              <Route path="/patientInfo/:id" component={PatientDetail} />
              <Route path="/dysphagia" component={Dysphagia} />
              <Route path="/dysphagiaInfo/:id" component={DysphagiaInfo} />
              <Route path="/alert" component={Alert} />
              {/* <Route path={"*"} component={ErrorPage} /> */}
              <footer className="footer">
                <div className="footer__wrap">
                  <p className="copy">
                    Copyright ©HicareNet Inc. All rights Reserved
                  </p>
                </div>
              </footer>
            </div>
          </div>
        </Switch>
      </Router>
    );
  }
}
export default App;
