import * as React from "react";
import { FunctionComponent, useEffect, useState } from "react";
//import { BackHandler } from "react-native"

import "../../css/font-awesome.min.css";
import "../../css/assessment.css";
import $ from "jquery";
import "jquery-ui";
import { Common } from "../../lib/utils/common";
import { useHistory } from "react-router-dom";
import * as _ from "lodash";
import { IAssessmentProps } from "./IAssessment";

import moment from "moment";
import { AdminDataProvider } from "../../lib/service/AdminDataProvider";

export const Assessment: FunctionComponent<IAssessmentProps> = (props) => {
  const history = useHistory();
  const common = new Common();

  const search = window.location.search;
  console.log(search);
  const params = new URLSearchParams(search);
  const insungSeq = params.get("insungSeq");

  //const [currentQuestion, setCurrentQuestion] = useState(0);
  //const [nextQuestion, setNextQuestion] = useState(0);

  useEffect(() => {
    common.ResizePage();
    const fetchArticles = async () => {};
  }, [props]);

  let nextQuestion = 0;
  let currentQuestion = 0;
  function nextStep() {
    console.log("current", currentQuestion);
    console.log("nextQuestion", nextQuestion);

    currentQuestion = nextQuestion;
    nextQuestion = nextQuestion + 1;
    //setCurrentQuestion(nextQuestion);
    //setNextQuestion(nextQuestion + 1);

    const selected = $(
      "input[name='ans" + currentQuestion + "']:checked"
    ).val();

    if (currentQuestion === 0) {
      $("#start").hide();
      $("#question").show();
    } else {
      if (selected === undefined) {
        //setNextQuestion(nextQuestion - 1);
        nextQuestion = nextQuestion - 1;
        console.log("nextQuestion", nextQuestion);
        alert("Please answer all questions to complete the health assessment.");
      } else {
        if (nextQuestion === 2) {
          $("#title").html(
            "On average, during the past week, how bad were our asthma symptoms when you wok<br/>e up in the moring."
          );
        } else if (nextQuestion === 3) {
          $("#title").html(
            "In general, during the past week, how limited were  you in your activities because of your asthma?"
          );
        } else if (nextQuestion === 4) {
          $("#title").html(
            "In general, during the past week, how much shortness of breath did you experience because of you asthma?"
          );
        } else if (nextQuestion === 5) {
          $("#title").html(
            "In general, during the past week, how much of the time  did you wheeze?"
          );
        } else if (nextQuestion === 6) {
          $("#title").html(
            "On average, during the past week, how many puffs of short-acting bronchodilator(eg. Ventolin) have you  used each day?"
          );
        }

        $("#question" + currentQuestion).hide();
        $("#question" + nextQuestion).show();
        $("#number" + currentQuestion).removeClass("active");
        $("#number" + nextQuestion).addClass("active");
      }
    }
  }

  const saveAssessment = async () => {
    const ans1 = $("input[name='ans1']:checked").val();
    const ans2 = $("input[name='ans2']:checked").val();
    const ans3 = $("input[name='ans3']:checked").val();
    const ans4 = $("input[name='ans4']:checked").val();
    const ans5 = $("input[name='ans5']:checked").val();
    const ans6 = $("input[name='ans6']:checked").val();

    console.log(
      "answer",
      ans1 + "," + ans2 + "," + ans3 + "," + ans4 + "," + ans5 + "," + ans6
    );
    const answer =
      ans1 + "," + ans2 + "," + ans3 + "," + ans4 + "," + ans5 + "," + ans6;
    const date = new Date();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    const month2 = month < 10 ? "0" + month : month;
    const day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();

    const assessDt = year + "-" + month2 + "-" + day;
    console.log("assessDt", assessDt);

    $("#question").hide();
    $("#end").show();

    const adminService = new AdminDataProvider();
    const json = {
      insungSeq: insungSeq,
      answer: answer,
      assessDt: assessDt,
      assessType: "COPD",
    };

    console.log("body", json);
    const main = await adminService.AddAssessment(json);
    //setCurrentQuestion(0);
    //setNextQuestion(1);
  };

  const goHome = () => {
    (window as any)["androidInterface"].goHome();
  };
  return (
    <div className="assessment">
      <main id="start" className="main">
        <strong>Hello,</strong>
        <p>
          <span>How are you feeling today?</span>
          <span>Let's start a health assessment.</span>
        </p>
        <button
          type="button"
          className="btn lg blue"
          onClick={() => nextStep()}
        >
          START
        </button>
      </main>
      <main id="question" className="content" style={{ display: "none" }}>
        <header>
          <h1>
            <span>PHQ-9</span>
          </h1>
          <ul>
            <li id="number1" className="active">
              <span>1-</span>
            </li>
            <li id="number2">
              <span>2-</span>
            </li>
            <li id="number3">
              <span>3-</span>
            </li>
            <li id="number4">
              <span>4-</span>
            </li>
            <li id="number5">
              <span>5-</span>
            </li>
            <li id="number6">
              <span>F</span>
            </li>
          </ul>
          <div className="ask">
            <strong>A</strong>
            <span id="title">
              On average, during the past week, how ofte n were you woken by
              your asthma during the night.
            </span>
          </div>
        </header>

        <section id="question1">
          <div className="tit">
            <i className="fa fa-arrow-right" aria-hidden="true"></i>
            <p>Little interest or pleasure in doing things</p>
          </div>
          <div className="form-item">
            <div
              className="inp"
              style={{ display: "flex", alignItems: "center" }}
            >
              <input type="radio" name="ans1" id="ans11" value="0" />
              <label htmlFor="ans11">Never</label>
            </div>
            <div
              className="inp"
              style={{ display: "flex", alignItems: "center" }}
            >
              <input type="radio" name="ans1" id="ans12" value="1" />
              <label htmlFor="ans12">Hardly ever</label>
            </div>
            <div
              className="inp"
              style={{ display: "flex", alignItems: "center" }}
            >
              <input type="radio" name="ans1" id="ans13" value="2" />
              <label htmlFor="ans13">A few minute</label>
            </div>
            <div
              className="inp"
              style={{ display: "flex", alignItems: "center" }}
            >
              <input type="radio" name="ans1" id="ans14" value="3" />
              <label htmlFor="ans14">Several times</label>
            </div>
            <div
              className="inp"
              style={{ display: "flex", alignItems: "center" }}
            >
              <input type="radio" name="ans1" id="ans15" value="4" />
              <label htmlFor="ans15">Many times</label>
            </div>
            <div
              className="inp"
              style={{ display: "flex", alignItems: "center" }}
            >
              <input type="radio" name="ans1" id="ans16" value="5" />
              <label htmlFor="ans16">A great many times</label>
            </div>
            <div
              className="inp"
              style={{ display: "flex", alignItems: "center" }}
            >
              <input type="radio" name="ans1" id="ans17" value="6" />
              <label htmlFor="ans17">Unable to sleep because of asthma</label>
            </div>
          </div>
          <button
            type="button"
            className="btn mlg blue full"
            onClick={() => nextStep()}
          >
            NEXT
          </button>
        </section>

        <section id="question2" style={{ display: "none" }}>
          <div className="tit">
            <i className="fa fa-arrow-right" aria-hidden="true"></i>
            <p>Little interest or pleasure in doing things</p>
          </div>
          <div className="form-item">
            <div className="inp">
              <input type="radio" name="ans2" id="ans21" value="0" />
              <label htmlFor="ans21">No symptoms</label>
            </div>
            <div className="inp">
              <input type="radio" name="ans2" id="ans22" value="1" />
              <label htmlFor="ans22">Very mild symptoms</label>
            </div>
            <div className="inp">
              <input type="radio" name="ans2" id="ans23" value="2" />
              <label htmlFor="ans23">Mild symptoms</label>
            </div>
            <div className="inp">
              <input type="radio" name="ans2" id="ans24" value="3" />
              <label htmlFor="ans24">Moderate symptoms</label>
            </div>
            <div className="inp">
              <input type="radio" name="ans2" id="ans25" value="4" />
              <label htmlFor="ans25">Quite severe symptoms</label>
            </div>
            <div className="inp">
              <input type="radio" name="ans2" id="ans26" value="5" />
              <label htmlFor="ans26">Severe symptoms</label>
            </div>
            <div className="inp">
              <input type="radio" name="ans2" id="ans27" value="6" />
              <label htmlFor="ans27">Very severe symptoms</label>
            </div>
          </div>
          <button
            type="button"
            className="btn mlg blue full"
            onClick={() => nextStep()}
          >
            NEXT
          </button>
        </section>

        <section id="question3" style={{ display: "none" }}>
          <div className="tit">
            <i className="fa fa-arrow-right" aria-hidden="true"></i>
            <p>Little interest or pleasure in doing things</p>
          </div>
          <div className="form-item">
            <div className="inp">
              <input type="radio" name="ans3" id="ans31" value="0" />
              <label htmlFor="ans31">Not limited at all</label>
            </div>
            <div className="inp">
              <input type="radio" name="ans3" id="ans32" value="1" />
              <label htmlFor="ans32">Very slightly limited</label>
            </div>
            <div className="inp">
              <input type="radio" name="ans3" id="ans33" value="2" />
              <label htmlFor="ans33">Slightly limited</label>
            </div>
            <div className="inp">
              <input type="radio" name="ans3" id="ans34" value="3" />
              <label htmlFor="ans34">Moderately limited</label>
            </div>
            <div className="inp">
              <input type="radio" name="ans3" id="ans35" value="4" />
              <label htmlFor="ans35">Very limited</label>
            </div>
            <div className="inp">
              <input type="radio" name="ans3" id="ans36" value="5" />
              <label htmlFor="ans36">Extremely limited</label>
            </div>
            <div className="inp">
              <input type="radio" name="ans3" id="ans37" value="6" />
              <label htmlFor="ans37">Totally limited</label>
            </div>
          </div>
          <button
            type="button"
            className="btn mlg blue full"
            onClick={() => nextStep()}
          >
            NEXT
          </button>
        </section>

        <section id="question4" style={{ display: "none" }}>
          <div className="tit">
            <i className="fa fa-arrow-right" aria-hidden="true"></i>
            <p>Little interest or pleasure in doing things</p>
          </div>
          <div className="form-item">
            <div className="inp">
              <input type="radio" name="ans4" id="ans41" value="0" />
              <label htmlFor="ans41">None</label>
            </div>
            <div className="inp">
              <input type="radio" name="ans4" id="ans42" value="1" />
              <label htmlFor="ans42">A very little</label>
            </div>
            <div className="inp">
              <input type="radio" name="ans4" id="ans43" value="2" />
              <label htmlFor="ans43">A little</label>
            </div>
            <div className="inp">
              <input type="radio" name="ans4" id="ans44" value="3" />
              <label htmlFor="ans44">A moderate amount</label>
            </div>
            <div className="inp">
              <input type="radio" name="ans4" id="ans45" value="4" />
              <label htmlFor="ans45">Quite a lot</label>
            </div>
            <div className="inp">
              <input type="radio" name="ans4" id="ans46" value="5" />
              <label htmlFor="ans46">A great deal</label>
            </div>
            <div className="inp">
              <input type="radio" name="ans4" id="ans47" value="6" />
              <label htmlFor="ans47">A very great deal</label>
            </div>
          </div>
          <button
            type="button"
            className="btn mlg blue full"
            onClick={() => nextStep()}
          >
            NEXT
          </button>
        </section>

        <section id="question5" style={{ display: "none" }}>
          <div className="tit">
            <i className="fa fa-arrow-right" aria-hidden="true"></i>
            <p>Little interest or pleasure in doing things</p>
          </div>
          <div className="form-item">
            <div className="inp">
              <input type="radio" name="ans5" id="ans51" value="0" />
              <label htmlFor="ans51">Not at all</label>
            </div>
            <div className="inp">
              <input type="radio" name="ans5" id="ans52" value="1" />
              <label htmlFor="ans52">Hardly any of the time</label>
            </div>
            <div className="inp">
              <input type="radio" name="ans5" id="ans53" value="2" />
              <label htmlFor="ans53">A little of the time</label>
            </div>
            <div className="inp">
              <input type="radio" name="ans5" id="ans54" value="3" />
              <label htmlFor="ans54">A moderate amount of the time</label>
            </div>
            <div className="inp">
              <input type="radio" name="ans5" id="ans55" value="4" />
              <label htmlFor="ans55">A lot of the time</label>
            </div>
            <div className="inp">
              <input type="radio" name="ans5" id="ans56" value="5" />
              <label htmlFor="ans56">Most of the time</label>
            </div>
            <div className="inp">
              <input type="radio" name="ans5" id="ans57" value="6" />
              <label htmlFor="ans57">All the time</label>
            </div>
          </div>
          <button
            type="button"
            className="btn mlg blue full"
            onClick={() => nextStep()}
          >
            NEXT
          </button>
        </section>

        <section id="question6" style={{ display: "none" }}>
          <div className="tit">
            <i className="fa fa-arrow-right" aria-hidden="true"></i>
            <p>Little interest or pleasure in doing things</p>
          </div>
          <div className="form-item">
            <div className="inp">
              <input type="radio" name="ans6" id="ans61" value="0" />
              <label htmlFor="ans61">None</label>
            </div>
            <div className="inp">
              <input type="radio" name="ans6" id="ans62" value="1" />
              <label htmlFor="ans62">1-2 puffs most days</label>
            </div>
            <div className="inp">
              <input type="radio" name="ans6" id="ans63" value="2" />
              <label htmlFor="ans63">3-4 puffs most days</label>
            </div>
            <div className="inp">
              <input type="radio" name="ans6" id="ans64" value="3" />
              <label htmlFor="ans64">5-8 puffs most days</label>
            </div>
            <div className="inp">
              <input type="radio" name="ans6" id="ans65" value="4" />
              <label htmlFor="ans65">9-12 puffs most days</label>
            </div>
            <div className="inp">
              <input type="radio" name="ans6" id="ans66" value="5" />
              <label htmlFor="ans66">13-16 puffs most days</label>
            </div>
            <div className="inp">
              <input type="radio" name="ans6" id="ans67" value="6" />
              <label htmlFor="ans67">More than 16 puffs most days</label>
            </div>
          </div>
          <button
            type="button"
            className="btn mlg blue full"
            onClick={() => saveAssessment()}
          >
            DONE
          </button>
        </section>

        <div className="alert-info">
          <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
          <p>
            <a
              href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC1495268/#app1"
              target="_blank"
            >
              This self assessment is an example ▶
            </a>
          </p>
        </div>
      </main>
      <main id="end" className="main" style={{ display: "none" }}>
        <p>
          <span>
            Thank you for taking the time to write the questionnaire. If you
            have any questions, please contact your healthcare provider.
          </span>
        </p>
        <button type="button" className="btn lg blue" onClick={() => goHome()}>
          OK
        </button>
      </main>
    </div>
  );
};

export default Assessment;
