export const ko = {
  btn_barcode_upper: "해제되었습니다.",
  btn_close: "URL 주소가 유효하지 않습니다. 확인 후  다시 시도해 주세요.",
  btn_delete_upper: "인증되었습니다.",
  btn_download: "URL 주소의 유효성체크는 필수입니다.",
  btn_edit: "통화수락시간",
  btn_id_duplication_check: "Address(URL)",
  btn_link: "ADDRESS(URL)",
  btn_ok: "관리자 등록",
  btn_password_change: "관리자",
  btn_print_upper: "관리자 목록",
  btn_regist: "건강문진",
  btn_register: "타임존(자동)",
  btn_register_new: "바코드",
  btn_reset: "생일",
  btn_save: "혈압",
  btn_save_changes: "체온",
  btn_save_upper: "수신자",
  btn_search: "발신자",
  btn_sign_in: "로그인",
  btn_unlink: "콜레스테롤",
  btn_verify: "콜레스테롤(CardioChek)",
  msg_admin_register: "회사/조직",
  msg_already_linked: "회사",
  msg_already_registed: "아이디 만들기",
  msg_basic_password: "삭제",
  msg_can_use: "기기",
  msg_cancel: "기기목록",
  msg_changed: "기기종류",
  msg_check_duplication: "기기",
  msg_choose_device_serial: "기기",
  msg_choose_ms: "디지털 스코프",
  msg_choose_patients: "의사",
  msg_delete_admin:
    "제품 구매 후 받으신 등록번호를 입력하시고 앱을 다운로드 해주세요.",
  msg_delete_ms: "통화시간",
  msg_delete_patient: "ECG",
  msg_deleted: "교육",
  msg_edit_ms: "종료시간",
  msg_edit_patient: "실패",
  msg_enter_ms_name: "여성",
  msg_enter_name: "이름",
  msg_enter_patient_name: "성별",
  msg_enter_search_name: "설정",
  msg_enter_serial: "혈당",
  msg_error_occurred: "HbA1c",
  msg_id: "로그",
  msg_id_length: "홈",
  msg_id_valid_all: "ID 중복검사",
  msg_id_valid_letter_number: "ID",
  msg_id_valid_sa: "INR",
  msg_id_valid_special_letter: "케톤",
  msg_incorrect_password: "성",
  msg_input_birth_gender: "마지막 업데이트",
  msg_input_company: "관리자등급",
  msg_input_id: "연동일",
  msg_input_ms_id: "연동기기",
  msg_input_ms_name: "연동의료진",
  msg_input_ms_password: "연동정보",
  msg_input_ms_password_confirm: "연동 환자",
  msg_input_ms_role: "연동 환자",
  msg_input_new_id: "연결",
  msg_input_password: "목록 & 등록",
  msg_input_password_confirm: "로그인",
  msg_input_patient_name: "로그아웃",
  msg_matched: "남성",
  msg_max_count: "연결 (환자-의료진) / (환자-기기)",
  msg_modified: "연결 (환자-의료진) / (환자-기기)",
  msg_no_data: "측정기록",
  msg_no_url_format: "측정항목",
  msg_notmatch_serial: "측정",
  msg_password_change: "의료진",
  msg_password_changed: "의료진 정보",
  msg_password_changed_1234: "의료진 목록",
  msg_password_length: "의료진 등록",
  msg_password_not_match: "의료진",
  msg_password_space: "한글에서는 없어야할 것 같습니다.",
  msg_patient_already_registed: "분",
  msg_patient_reset_password: "부재중",
  msg_pid: "이름/ID",
  msg_pid_already_registed: "이름",
  msg_register_admin: "다음",
  msg_registered: "번호",
  msg_reset_admin_password: "삭제",
  msg_reset_password: "기타",
  msg_sa_special_character: "비밀번호",
  msg_select_device: "비밀번호 변경",
  msg_select_ms: "비밀번호 확인",
  msg_select_patient: "비밀번호 확인",
  msg_select_type: "비밀번호 설정",
  msg_unlink_device: "비밀번호",
  msg_unlink_info: "환자",
  msg_unlink_ms: "환자-기기",
  msg_unlinked: "환자정보",
  msg_url_not_verified: "환자목록",
  msg_verified: "환자-의료진",
  msg_verify_address: "환자이름",
  txt_accept_time_upper: "환자등록",
  txt_address_url: "환자",
  txt_address_url_upper: "PFT",
  txt_admin_register_upper: "PID (LINK ID)",
  txt_administrator: "이전",
  txt_administrator_list_upper: "미리보기",
  txt_assessment: "QR코드",
  txt_auto_timezone_upper: "최근등록(회사)",
  txt_barcode_upper: "Receptionist",
  txt_birth_upper: "등록일",
  txt_blood_pressure: "환자, 기기, 의료진을 등록하세요.",
  txt_body_temperature: "환자, 의료진을 등록하세요.",
  txt_callee_name_upper: "등록되었습니다.",
  txt_caller_name_upper: "요청시각",
  txt_capnography: "권한",
  txt_cholesterol: "초",
  txt_cholesterol_cardiochek: "제품번호",
  txt_company_organization: "서비스",
  txt_company_upper: "시리얼넘버(휴대폰번호)",
  txt_create_id_upper: "SpO₂",
  txt_delete_upper: "시작시간",
  txt_device: "상태",
  txt_device_list: "걸음수",
  txt_device_type: "Stethoscope",
  txt_devices: "성공",
  txt_devices_upper: "TC",
  txt_digital_microscope: "테스트그룹",
  txt_doctor: "테스트",
  txt_download_guide: "제품을 구매하여 주셔서 감사합니다.",
  txt_duration_upper: "오늘",
  txt_ecg: "총 관리자",
  txt_education: "총",
  txt_end_time_upper: "기기종류",
  txt_fail: "Uric Acid",
  txt_female: "Urine(spot)",
  txt_first_name: "Urine(stick)",
  txt_gender_upper: "화상통화",
  txt_general_settings: "화상통화기록",
  txt_glucose: "화상통화",
  txt_hba1c: "통화상태",
  txt_history: "허리둘레",
  txt_home: "WEB CHART",
  txt_id_duplication_check: "WebChart",
  txt_id_upper: "Webpage",
  txt_inr: "WEBPAGE",
  txt_ketone: "체중",
};
