/* react */
import { Fragment, FunctionComponent, useEffect, useState } from "react";

import { IPatientProps } from "./IPatient";
import "jquery-ui";
import { Common } from "../../lib/utils/common";
import { useHistory } from "react-router-dom";
// import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
import moment from "moment";
// import "devextreme/dist/css/dx.generic.custom-hicareAdmin.css";
// import "../../css/dx.generic.custom-hicareAdmin.css";
import "../../css/font-awesome.min.css";
import "../../css/layout.css";
import "../../css/custom.css";
// import "jquery-ui/themes/base/jquery-ui.css";
// import "../../css/jquery-ui.css";
import {
  Button,
  DateBox,
  TextBox,
  SelectBox,
  Popup,
  CheckBox,
} from "devextreme-react";
import Chart, {
  ArgumentAxis,
  Label,
  Legend,
  Series,
  Tooltip,
} from "devextreme-react/chart";
import * as _ from "lodash";
import DataGrid, {
  Column,
  Pager,
  Paging,
  LoadPanel,
} from "devextreme-react/data-grid";
import $ from "jquery";
import { AdminDataProvider } from "../../lib/service/AdminDataProvider";
import sBox from "devextreme/ui/select_box";
import tBox from "devextreme/ui/text_box";
import dBox from "devextreme/ui/date_box";
import { alert } from "devextreme/ui/dialog";
import { lang } from "../../lib/utils/language";
import { locale, loadMessages, formatMessage } from "devextreme/localization";
loadMessages(lang);
locale("" + localStorage.getItem("lang"));

// name을 제외하고 sessionStorage를 초기화 하는 함수
// function clearSessionStorageExceptName(): void {
//   // "name"에 해당하는 값을 가져오기
//   const nameValue = sessionStorage.getItem("name");

//   // sessionStorage를 비우기
//   sessionStorage.clear();

//   // 다시 "name" 값을 설정하기 (다른 데이터가 없다면 비워진 상태로 설정될 것입니다)
//   sessionStorage.setItem("name", nameValue || "");
// }

export const Patient: FunctionComponent<IPatientProps> = (props) => {
  const history = useHistory();
  const common = new Common();
  const [patient, setPatient] = useState([] as any);
  const [duplicate, setDuplicate] = useState(true);
  const [startDate, setStartDate] = useState<Date | undefined>(undefined);
  const [daterangeState, setDateRangeState] = useState("D");
  const [gameState, setGameState] = useState("");
  const [startMinDate, setStartMinState] = useState<Date | undefined>(
    undefined
  );
  const [startMaxDate, setStartMaxState] = useState<Date | undefined>(
    undefined
  );
  const [endMinDate, setEndMinState] = useState<Date | undefined>(undefined);
  const [endMaxDate, setEndMaxState] = useState<Date | undefined>(undefined);
  const [endDateBoxState, setEndDateBoxState] = useState(false);
  const [rangeVal, setRangeVal] = useState(String);
  const [age, setAges] = useState([] as any);
  const [Count, setCount] = useState(Number as any);
  const [MaleCount, setMaleCount] = useState(Number as any);
  const [FemaleCount, setFemaleCount] = useState([] as any);
  const [Top3, setTop3] = useState([] as any);
  const [list, setList] = useState([] as any);
  const [isPopupVisible, setPopupVisibility] = useState(false);
  const [NoC, setNoColumnState] = useState(true);
  const [MeasureC, setMeasureColumnState] = useState(true);
  const [PidC, setPidState] = useState(true);
  const [PlidC, setPlidState] = useState(true);
  const [AgeC, setAgeState] = useState(true);
  const [SexC, setSexState] = useState(true);
  const [GNC, setGNState] = useState(true);
  const [GLC, setGLState] = useState(true);
  const [GPC, setGPState] = useState(true);
  const [GTC, setGTState] = useState(true);
  const [offset, setOffset] = useState(10);
  const [limmit, setLimmit] = useState(0);
  /* componentDidMount && componentDidUpdate */

  useEffect(() => {
    const adminService = new AdminDataProvider();
    common.ResizePage();

    setStartMinState(moment("2023-01-01").toDate());
    setStartMaxState(moment("2050-12-31").toDate());
    setEndMinState(moment("2023-01-01").toDate());
    setEndMaxState(moment("2050-12-31").toDate());

    // const fetchArticles = async () => {
    //   const adminService = new AdminDataProvider();

    //   const main = await adminService.GetMainList();

    //   setStartDate(new Date());
    //   setPatient(main.data.patient);
    // };
    if (sessionStorage.getItem("name") !== null) {
      if (sessionStorage.getItem("p_startDate")) {
        const dateRangeState = sessionStorage.getItem("p_daterangeState");
        const gameState = sessionStorage.getItem("p_appId");
        const endDateBoxState = sessionStorage.getItem("p_endDateBoxState");
        setDateRangeState(dateRangeState !== null ? dateRangeState : "D");
        setGameState(gameState !== null ? gameState : "");
        setEndDateBoxState(
          endDateBoxState !== null ? JSON.parse(endDateBoxState) : ""
        );

        valToTag(
          "startDate",
          dBox,
          adminService.dateFormat(sessionStorage.getItem("p_startDate"))
        );
        valToTag(
          "endDate",
          dBox,
          adminService.dateFormat(sessionStorage.getItem("p_endDate"))
        );
        valToTag("name", tBox, sessionStorage.getItem("p_patientName"));
        const json = {
          offset: 0,
          limit: 5000,
          startDate: adminService.dateFormat(
            sessionStorage.getItem("p_startDate")
          ),
          endDate: adminService.dateFormat(sessionStorage.getItem("p_endDate")),
          patientName: sessionStorage.getItem("p_patientName"),
          appId: sessionStorage.getItem("p_appId"),
        };

        adminService.searchRehabilitation(json).then(async (e: any) => {
          console.log(e);

          // 왼쪽 요약 항목 값 만들기
          let totalCounts = {
            Count: 0,
            MaleCount: 0,
            FemaleCount: 0,
          };

          e.data.data.age.forEach(function (item: {
            Count: any;
            MaleCount: any;
            FemaleCount: any;
          }) {
            totalCounts.Count += parseInt(item.Count);
            totalCounts.MaleCount += parseInt(item.MaleCount);
            totalCounts.FemaleCount += parseInt(item.FemaleCount);
          });

          // 차트 데이터 만들기
          let seriesData = e.data.data.age.map(function (item: {
            AgeGroup: any;
            Count: any;
            MaleCount: any;
            FemaleCount: any;
          }) {
            return {
              arg: item.AgeGroup,
              val: parseInt(item.Count),
            };
          });

          // let seriesData = e.data.data.age;

          // Top3 저장
          let Top3 = _.slice(e.data.data.ranking, 0, 3);

          // 리스트 저장
          let list = e.data.data.list;

          e.data.data.list.forEach(
            (patient: {
              latest_input_utc_dt: moment.MomentInput;
              dob: moment.MomentInput;
              age: number;
              platform_id: string;
              source: string;
              seq: number;
            }) => {
              patient.latest_input_utc_dt = moment(
                patient.latest_input_utc_dt
              ).format("DD/MM/YYYY HH:mm:ss");

              const dob = moment(patient.dob, "YYYY-MM-DD HH:mm:ss.SSS");
              const now = moment();
              const age = now.diff(dob, "years");
              patient.age = age;
              patient.platform_id = patient.source + patient.seq;
            }
          );

          //값 저장
          setAges(seriesData);
          setCount(totalCounts.Count);
          setMaleCount(totalCounts.MaleCount);
          setFemaleCount(totalCounts.FemaleCount);
          setTop3(Top3);
          setList(list);

          console.log(Top3);
          console.log(list);
        });
        if (dateRangeState === "D") {
          setStartMinState(moment("2023-01-01").toDate());
          setStartMaxState(_dev("endDate", dBox).option("value"));
          setEndMinState(_dev("startDate", dBox).option("value"));
          setEndMaxState(moment("2050-12-31").toDate());
        }
      } else {
        btnSearch("");
        setStartMinState(moment("2023-01-01").toDate());
        setStartMaxState(_dev("endDate", dBox).option("value"));
        setEndMinState(_dev("startDate", dBox).option("value"));
        setEndMaxState(moment("2050-12-31").toDate());
      }
    } else {
      history.push("/auth/login");
    }
    // }, [rangeVal, props]);
  }, [props]);

  // 날짜 범위 셀렉트 박스 항목
  const selectBoxData = [
    {
      id: 1,
      name: "Day",
      value: "D",
    },
    {
      id: 2,
      name: "Week",
      value: "W",
    },
    {
      id: 3,
      name: "Month",
      value: "M",
    },
  ];

  // game 셀렉트 박스 항목
  const selectBoxData2 = [
    {
      id: 0,
      name: "All",
      value: "",
    },
    {
      id: 1,
      name: "SmartROM",
      value: "SmartROM",
    },
    {
      id: 2,
      name: "Gates",
      value: "Gates",
    },
    {
      id: 3,
      name: "SmartClean",
      value: "SmartClean",
    },
    {
      id: 4,
      name: "SmartBubblePop",
      value: "SmartBubblePop",
    },
    {
      id: 5,
      name: "SmartSortAndPlace",
      value: "SmartSortAndPlace",
    },
    {
      id: 6,
      name: "SmartbArm",
      value: "SmartbArm",
    },
    {
      id: 7,
      name: "SmartPress",
      value: "SmartPress",
    },
    {
      id: 8,
      name: "Runner",
      value: "Runner",
    },
  ];

  // 날짜 범위 설정을 셀렉트 박스로 설정하였을때
  // const setDate = () => {
  //   const startdate = _dev("daterange", sBox).option("value")[0];
  //   const enddate = _dev("daterange", sBox).option("value")[1];

  //   valToTag("startDate", dBox, startdate);
  //   valToTag("endDate", dBox, enddate);
  // };

  const setDate = () => {
    const dRangeVal = _dev("daterange", sBox).option("value");
    if (dRangeVal === "D") {
      setEndDateBoxState(false);
      valToTag("startDate", dBox, moment());
      valToTag("endDate", dBox, moment());
      setStartMinState(moment("2023-01-01").toDate());
      setStartMaxState(_dev("endDate", dBox).option("value"));
      setEndMinState(_dev("startDate", dBox).option("value"));
      setEndMaxState(moment("2050-12-31").toDate());
      setDateRangeState("D");
    } else if (dRangeVal === "W") {
      setEndDateBoxState(true);
      valToTag("startDate", dBox, moment().add(-7, "days"));
      valToTag("endDate", dBox, moment());
      setStartMinState(moment("2023-01-01").toDate());
      setStartMaxState(moment().add(-7, "days").toDate());
      setEndMinState(moment("2023-01-01").toDate());
      setDateRangeState("W");
    } else if (dRangeVal === "M") {
      setEndDateBoxState(true);
      valToTag("startDate", dBox, moment().startOf("month"));
      valToTag("endDate", dBox, moment().endOf("month"));
      setStartMinState(moment("2023-01-01").toDate());
      setStartMaxState(moment().toDate());
      setEndMinState(moment("2023-01-01").toDate());
      setDateRangeState("M");
    } else {
      setEndDateBoxState(false);
      setStartMinState(moment("2023-01-01").toDate());
      setStartMaxState(_dev("endDate", dBox).option("value"));
      setEndMinState(_dev("startDate", dBox).option("value"));
      setEndMaxState(moment("2050-12-31").toDate());
      setDateRangeState("D");
    }

    // if (dRangeVal === "W") {
    //   valToTag("startDate", dBox, moment().add(-7, "days"));
    //   valToTag("endDate", dBox, moment());
    // } else if (dRangeVal === "M") {
    //   valToTag("startDate", dBox, moment().add(-30, "days"));
    //   valToTag("endDate", dBox, moment());
    // } else {
    //   valToTag("startDate", dBox, moment());
    //   valToTag("endDate", dBox, moment());
    // }
  };

  const setGame = () => {
    const gameVal = _dev("game", sBox).option("value");
    if (gameVal === "") {
      setGameState("");
    } else if (gameVal === "SmartROM") {
      setGameState("SmartROM");
    } else if (gameVal === "Gates") {
      setGameState("Gates");
    } else if (gameVal === "SmartClean") {
      setGameState("SmartClean");
    } else if (gameVal === "SmartBubblePop") {
      setGameState("SmartBubblePop");
    } else if (gameVal === "SmartSortAndPlace") {
      setGameState("SmartSortAndPlace");
    } else if (gameVal === "SmartbArm") {
      setGameState("SmartbArm ");
    } else if (gameVal === "SmartPress") {
      setGameState("SmartPress");
    } else if (gameVal === "Runner") {
      setGameState("Runner");
    } else {
      setGameState("");
    }
  };

  // reset 버튼을 눌렀을때 함수
  const resetSearch = () => {
    setEndDateBoxState(false);
    setStartMinState(moment("2023-01-01").toDate());
    setStartMaxState(moment("2050-12-31").toDate());
    setEndMinState(moment("2023-01-01").toDate());
    setEndMaxState(moment("2050-12-31").toDate());
    setDateRangeState("D");
    valToTag("startDate", dBox, moment());
    valToTag("endDate", dBox, moment());
    valToTag("name", tBox, "");
    setGameState("");
  };

  // datebox 태그의 value 값을 다른 value로 넣는 함수
  const valToTag = (e: any, box: any, value: any) => {
    const id = document.getElementById(e) as HTMLElement;
    const instance = box.getInstance(id);
    instance.option("value", value);
  };

  // selectbox 태그의 value 값을 다른 value로 넣는 함수
  const valToTag3 = (e: any, value: any) => {
    const id = document.getElementById(e) as HTMLElement;
    id.getElementsByTagName("input")[1].value = value;
  };

  // 태그의 정보를 불러오는 함수
  const _dev = (e: any, d: any) => {
    const id = document.getElementById(e) as HTMLElement;
    return d.getInstance(id);
  };

  // startDate 선택시 endDate 값 변경
  const funcSetEndDate = () => {
    const dRangeVal = _dev("daterange", sBox).option("value");
    const startDate = _dev("startDate", dBox).option("value");
    if (dRangeVal === "W") {
      valToTag("endDate", dBox, moment(startDate).add(7, "days"));
    } else if (dRangeVal === "M") {
      valToTag("endDate", dBox, moment(startDate).endOf("month"));
    } else {
      setStartMinState(moment("2023-01-01").toDate());
      setStartMaxState(_dev("endDate", dBox).option("value"));
      setEndMinState(_dev("startDate", dBox).option("value"));
      setEndMaxState(moment("2050-12-31").toDate());
    }
  };

  // endDate 선택시 endDate 값 변경
  const funcSetStartDate = () => {
    const dRangeVal = _dev("daterange", sBox).option("value");
    if (dRangeVal === "D") {
      setStartMinState(moment("2023-01-01").toDate());
      setStartMaxState(_dev("endDate", dBox).option("value"));
      setEndMinState(_dev("startDate", dBox).option("value"));
      setEndMaxState(moment("2050-12-31").toDate());
    }
  };

  // 검색버튼 함수
  const btnSearch = async (d: any) => {
    const adminService = new AdminDataProvider();
    // const offset = _dev("offset", sBox).option("value");
    const offset = 0;
    // const limit = _dev("limit", tBox).option("value");
    const limit = 5000;
    let startDate;
    let endDate;
    const name = _dev("name", tBox).option("value");
    const game = _dev("game", sBox).option("value");

    startDate = _dev("startDate", dBox).option("value");
    endDate = _dev("endDate", dBox).option("value");

    sessionStorage.setItem("p_daterangeState", daterangeState);
    sessionStorage.setItem("p_startDate", startDate);
    sessionStorage.setItem("p_endDate", endDate);
    sessionStorage.setItem("p_endDateBoxState", endDateBoxState.toString());
    sessionStorage.setItem("p_patientName", name);
    sessionStorage.setItem("p_appId", game);

    const json = {
      offset: offset,
      limit: limit,
      startDate: adminService.dateFormat(startDate),
      endDate: adminService.dateFormat(endDate),
      patientName: name,
      appId: game,
    };

    adminService.searchRehabilitation(json).then(async (e: any) => {
      console.log(e);

      // 왼쪽 요약 항목 값 만들기
      let totalCounts = {
        Count: 0,
        MaleCount: 0,
        FemaleCount: 0,
      };

      e.data.data.age.forEach(function (item: {
        Count: any;
        MaleCount: any;
        FemaleCount: any;
      }) {
        totalCounts.Count += parseInt(item.Count);
        totalCounts.MaleCount += parseInt(item.MaleCount);
        totalCounts.FemaleCount += parseInt(item.FemaleCount);
      });

      // 차트 데이터 만들기
      let seriesData = e.data.data.age.map(function (item: {
        AgeGroup: any;
        Count: any;
        MaleCount: any;
        FemaleCount: any;
      }) {
        return {
          arg: item.AgeGroup,
          val: parseInt(item.Count),
        };
      });

      // let seriesData = e.data.data.age;

      // Top3 저장
      let Top3 = _.slice(e.data.data.ranking, 0, 3);

      // 리스트 저장
      let list = e.data.data.list;

      e.data.data.list.forEach(
        (patient: {
          latest_input_utc_dt: moment.MomentInput;
          dob: moment.MomentInput;
          age: number;
          platform_id: string;
          source: string;
          seq: number;
        }) => {
          patient.latest_input_utc_dt = moment(
            patient.latest_input_utc_dt
          ).format("DD/MM/YYYY HH:mm:ss");

          const dob = moment(patient.dob, "YYYY-MM-DD HH:mm:ss.SSS");
          const now = moment();
          const age = now.diff(dob, "years");
          patient.age = age;
          patient.platform_id = patient.source + patient.seq;
        }
      );

      //값 저장
      setAges(seriesData);
      setCount(totalCounts.Count);
      setMaleCount(totalCounts.MaleCount);
      setFemaleCount(totalCounts.FemaleCount);
      setTop3(Top3);
      setList(list);

      console.log(Top3);
      console.log(list);
    });
  };

  // 환자 상세페이지로 이동
  const goPatientDetail = (e: any) => {
    window.location.href = `/patientInfo/${e.data.patient_id}`;
    console.log(e);
  };

  // 컬럼 출력 설정 팝업 켜기/끄기
  const openDisplayPopup = (e: any) => {
    setPopupVisibility(!isPopupVisible);
  };

  // No 컬럼 출력 변경
  const changeNoCValue = (e: any) => {
    setNoColumnState(!NoC);
  };

  // 측정일자 컬럼 출력 변경
  const changeMeasureCValue = (e: any) => {
    setMeasureColumnState(!MeasureC);
  };

  // PatientId 컬럼 출력 변경
  const changePidCValue = (e: any) => {
    setPidState(!PidC);
  };

  // PlatformId 컬럼 출력 변경
  const changePlidCValue = (e: any) => {
    setPlidState(!PlidC);
  };

  // Age 컬럼 출력 변경
  const changeAgeCValue = (e: any) => {
    setAgeState(!AgeC);
  };

  // Sex 컬럼 출력 변경
  const changeSexCValue = (e: any) => {
    setSexState(!SexC);
  };

  // 게임이름 컬럼 출력 변경
  const changeGNCValue = (e: any) => {
    setGNState(!GNC);
  };

  // 게임레벨 컬럼 출력 변경
  const changeGLCValue = (e: any) => {
    setGLState(!GLC);
  };

  // 게임레벨 컬럼 출력 변경
  const changeGPCValue = (e: any) => {
    setGPState(!GPC);
  };

  // 게임시간 컬럼 출력 변경
  const changeGTCValue = (e: any) => {
    setGTState(!GTC);
  };

  const renderContent = () => {
    return (
      <div>
        <CheckBox
          text="No"
          defaultValue={NoC}
          onValueChanged={changeNoCValue}
        />
        <br />
        <CheckBox
          text="Measurement Date"
          defaultValue={MeasureC}
          onValueChanged={changeMeasureCValue}
          style={{ marginTop: "10px" }}
        />
        <br />
        <CheckBox
          text="PatinetId"
          defaultValue={PidC}
          onValueChanged={changePidCValue}
          style={{ marginTop: "10px" }}
        />
        <br />
        <CheckBox
          text="PlatformId"
          defaultValue={PlidC}
          onValueChanged={changePlidCValue}
          style={{ marginTop: "10px" }}
        />
        <br />
        <CheckBox
          text="Age"
          defaultValue={AgeC}
          onValueChanged={changeAgeCValue}
          style={{ marginTop: "10px" }}
        />
        <br />
        <CheckBox
          text="Sex"
          defaultValue={SexC}
          onValueChanged={changeSexCValue}
          style={{ marginTop: "10px" }}
        />
        <br />
        <CheckBox
          text="Game Name"
          defaultValue={GNC}
          onValueChanged={changeGNCValue}
          style={{ marginTop: "10px" }}
        />
        <br />
        <CheckBox
          text="IsSuccess"
          defaultValue={GLC}
          onValueChanged={changeGLCValue}
          style={{ marginTop: "10px" }}
        />
        <br />
        <CheckBox
          text="Game Point"
          defaultValue={GPC}
          onValueChanged={changeGPCValue}
          style={{ marginTop: "10px" }}
        />
        <br />
        <CheckBox
          text="Game Time"
          defaultValue={GTC}
          onValueChanged={changeGTCValue}
          style={{ marginTop: "10px" }}
        />
        <br />
      </div>
    );
  };

  return (
    <main id="content" className="content">
      <Popup
        width={250}
        height={435}
        title="Column Chooser"
        visible={isPopupVisible}
        contentRender={renderContent}
        onHiding={openDisplayPopup}
      />
      <section className="sub-top">
        <header>
          <div className="page-history">
            <ul>
              <li>
                <a href="/main">
                  <i className="fa fa-home" aria-hidden="true"></i>
                </a>
              </li>
              <li className="current">
                <span>PRS</span>
              </li>
            </ul>
          </div>
        </header>

        <article
          className="reg-form insert"
          style={{ borderTop: "1px solid #0373c6" }}
        >
          <form action="" name="regForm">
            <fieldset>
              <article>
                <div
                  className="leftcont"
                  style={{
                    color: "#0373c6",
                    marginLeft: "10px",
                    marginRight: "12px",
                  }}
                >
                  <i className="fa fa-calendar icon-img fa-2x"></i>
                  <br />
                  <strong style={{ fontSize: "30px" }}>PRS</strong>
                  <br />
                  <strong>Personalized Rehabitation System</strong>
                </div>

                <div className="cont">
                  <div className="contline">
                    <SelectBox
                      id="daterange"
                      dataSource={selectBoxData}
                      defaultValue={selectBoxData[0]}
                      displayExpr="name"
                      onValueChanged={setDate}
                      valueExpr="value"
                      value={daterangeState}
                      style={{ width: "150px", marginRight: "5px" }}
                    ></SelectBox>

                    <DateBox
                      id="startDate"
                      className="prs-datebox"
                      defaultValue={moment()}
                      displayFormat={"yyyy-MM-dd"}
                      onValueChanged={funcSetEndDate}
                      min={startMinDate}
                      max={startMaxDate}
                    ></DateBox>
                    <div style={{ paddingTop: "4px" }}>&#126;</div>
                    <DateBox
                      id="endDate"
                      className="prs-datebox"
                      defaultValue={moment()}
                      displayFormat={"yyyy-MM-dd"}
                      disabled={endDateBoxState}
                      onValueChanged={funcSetStartDate}
                      min={endMinDate}
                      max={endMaxDate}
                    ></DateBox>
                  </div>
                  <div className="contline">
                    <TextBox
                      id="name"
                      placeholder="Patient Id"
                      defaultValue={""}
                      style={{ width: "356px", marginRight: "10px" }}
                    />
                    <SelectBox
                      id="game"
                      dataSource={selectBoxData2}
                      onValueChanged={setGame}
                      defaultValue=""
                      displayExpr="name"
                      valueExpr="value"
                      placeholder="Game"
                      value={gameState}
                      style={{ width: "360px", marginRight: "10px" }}
                    ></SelectBox>
                    <Button
                      style={{ marginRight: "10px" }}
                      text="(R)"
                      type="normal"
                      onClick={resetSearch}
                      // stylingMode="contained"
                      // onClick={btnRst}
                    />
                    <Button
                      width={50}
                      icon="fa fa-search"
                      type="normal"
                      // stylingMode="contained"
                      onClick={btnSearch}
                    />
                  </div>
                </div>
              </article>
            </fieldset>
          </form>
        </article>
      </section>
      <section>
        <div className="prs-middle-cont" style={{ marginTop: "10px" }}>
          <div
            className="card"
            style={{
              border: "1px solid #0373c6",
              padding: "10px",
              width: "40%",
            }}
          >
            {" "}
            <header>
              <h3>Patients Info</h3>
            </header>
            <section style={{ width: "100%", display: "flex" }}>
              <div className="cardcont" style={{ width: "49%" }}>
                <div
                  style={{
                    backgroundColor: "silver",
                    height: "80px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <h4>(E) {Count}</h4>
                </div>
                <div style={{ display: "flex", marginTop: "10px" }}>
                  <div
                    style={{
                      backgroundColor: "skyblue",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      width: "49%",
                      height: "50px",
                    }}
                  >
                    <h4>(&#9794;) {MaleCount}</h4>
                  </div>
                  <div
                    style={{
                      backgroundColor: "pink",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      width: "49%",
                      marginLeft: "2%",
                      height: "50px",
                    }}
                  >
                    <h4>(&#9792;) {FemaleCount}</h4>
                  </div>
                </div>
              </div>
              <div
                className="cardcont"
                style={{ width: "49%", marginRight: "1%", marginLeft: "1%" }}
              >
                <Chart
                  dataSource={age}
                  id="chart"
                  style={{ height: "100%", width: "100%" }}
                >
                  <ArgumentAxis>
                    <Label format="decimal" visible={true} />
                  </ArgumentAxis>
                  <Tooltip enabled={true} />

                  <Series type="bar" />

                  <Legend visible={false} />
                </Chart>
              </div>
            </section>
          </div>
          <div
            className="card"
            style={{
              border: "1px solid #0373c6",
              padding: "10px",
              marginLeft: "10px",
              width: "59%",
            }}
          >
            {" "}
            <header>
              <h3>Contents Top 3</h3>
            </header>
            <section style={{ width: "100%" }}>
              <DataGrid
                id="gridCont1"
                dataSource={Top3}
                columnHidingEnabled={true}
                noDataText="No data"
                selection={{ mode: "single" }}
                hoverStateEnabled={true}
                className="type-ct"
                showBorders
                style={{ with: "100%" }}
              >
                <LoadPanel enabled />
                <Column dataField="app_id" caption="Contents Name" />
                <Column dataField="max_score" caption="Total Counts" />
                <Column
                  dataField="success_count"
                  caption="Success Count"
                  // cellRender={(e) => {
                  //   return e.data.is_success === 0 ? (
                  //     <span style={{ color: "red", fontWeight: "bold" }}>
                  //       Failure
                  //     </span>
                  //   ) : e.data.is_success === 1 ? (
                  //     <span style={{ color: "green", fontWeight: "bold" }}>
                  //       Success
                  //     </span>
                  //   ) : (
                  //     <span></span>
                  //   );
                  // }}
                />
                <Column
                  width={100}
                  dataField="failure_count"
                  caption="Failure Count"
                />
                <Column dataField="avg_time" caption="Average Time" />
              </DataGrid>
            </section>
          </div>
        </div>
      </section>

      <section
        style={{
          border: "1px solid #0373c6",
          marginTop: "10px",
          padding: "10px",
        }}
      >
        <header
          style={{ display: "flex", width: "100%", marginBottom: "10px" }}
        >
          <h3 style={{ marginRight: "auto" }}>Patient Data List</h3>
          <Button text="Display" onClick={openDisplayPopup} />
        </header>

        <section className="sub-cont__wrap">
          <article>
            <div className="tbl-wrap">
              <DataGrid
                id="gridCont1"
                dataSource={list}
                columnHidingEnabled={true}
                noDataText="No data"
                selection={{ mode: "single" }}
                hoverStateEnabled={true}
                allowColumnResizing={true}
                className="type-ct"
                onRowClick={goPatientDetail}
                showBorders
              >
                <LoadPanel enabled />
                <Column
                  caption="No"
                  width={50}
                  // cellRender={(e) => {
                  //   return e.row.dataIndex + 1;
                  // }}
                  cellRender={(e) => {
                    const pageIndex = e.component.pageIndex();
                    const pageSize = e.component.pageSize();
                    const dataIndex = e.row.dataIndex;
                    const calculatedNo = pageIndex * pageSize + dataIndex + 1;
                    return calculatedNo;
                  }}
                  visible={NoC}
                />
                <Column
                  dataField="latest_input_utc_dt"
                  caption="Measurment Date"
                  format="yyyy-MM-dd"
                  visible={MeasureC}
                />
                <Column
                  dataField="patient_id"
                  caption="PatinetId"
                  visible={PidC}
                />
                <Column
                  dataField="platform_id"
                  caption="PlatformId"
                  visible={PlidC}
                />
                <Column
                  width={50}
                  dataField="age"
                  caption="Age"
                  visible={AgeC}
                />
                <Column
                  width={50}
                  dataField="gender"
                  caption="Sex"
                  visible={SexC}
                />
                <Column
                  dataField="game_name"
                  caption="Game Name"
                  visible={GNC}
                />
                <Column
                  width={100}
                  dataField="is_success"
                  caption="IsSuccess"
                  visible={GLC}
                  cellRender={(e) => {
                    return e.data.is_success === 0 ? (
                      <span style={{ color: "red", fontWeight: "bold" }}>
                        Failure
                      </span>
                    ) : e.data.is_success === 1 ? (
                      <span style={{ color: "green", fontWeight: "bold" }}>
                        Success
                      </span>
                    ) : (
                      <span></span>
                    );
                  }}
                />
                <Column
                  width={100}
                  dataField="score"
                  caption="Game Point"
                  visible={GPC}
                />
                <Column
                  width={100}
                  dataField="time"
                  caption="Game Time"
                  visible={GTC}
                />
                {/* 
                <Column
                  dataField="name"
                  caption="NAME"
                  cellTemplate={(container: any, options: any) => {
                    $(
                      "<a href='" +
                        "/patientInfo/" +
                        options.data.insungSeq +
                        "' class='link'>"
                    )
                      .append(options.value)
                      .appendTo(container);
                  }}
                />
                <Column dataField="gender" caption="GENDER" />
                <Column
                  dataField="linkDevice"
                  caption="LINKED DEVICE"
                  cellTemplate={(container: any, options: any) => {
                    $("<span class='icon'>")
                      .append($("<i>", { class: "fa fa-laptop" }))
                      .appendTo(container)
                      .append("<span> " + options.value + " </span>")
                      .appendTo(container);
                  }}
                />
                <Column
                  dataField="linkDoctor"
                  caption="LINKED DOCTOR"
                  cellTemplate={(container: any, options: any) => {
                    $("<span class='icon'>")
                      .append($("<i>", { class: "fa fa-bar-chart" }))
                      .appendTo(container)
                      .append("<span> " + options.value + " </span>")
                      .appendTo(container)
                      .append($("<i>", { class: "fa fa-video-camera" }))
                      .appendTo(container)
                      .append("<span> " + options.value + " </span>")
                      .appendTo(container);
                  }}
                />
                <Column
                  dataField="inputUtcDt"
                  caption="REGISTER DATE"
                  dataType="date"
                  format="yyyy-MM-dd HH:mm:ss"
                /> */}

                <Paging defaultPageSize={10} />
                <Pager
                  visible={true}
                  allowedPageSizes={[10, 20, "all"]}
                  displayMode={"full"}
                  showPageSizeSelector={true}
                  showInfo={true}
                  showNavigationButtons={true}
                />
              </DataGrid>
            </div>
          </article>
        </section>
      </section>
    </main>
  );
};

export default Patient;
