/* react */
import * as React from "react";
import { FunctionComponent, useEffect, useState } from "react";

import { IPatientDetailProps } from "./IPatientDetail";
import "jquery-ui";
import { Common } from "../../lib/utils/common";
import { useHistory, useParams } from "react-router-dom";

import { Button, DateBox, SelectBox, Popup, CheckBox } from "devextreme-react";
import {
  Chart,
  Series,
  CommonSeriesSettings,
  Format,
  ValueAxis,
  Label,
  Legend,
  Tooltip,
  Tick,
} from "devextreme-react/chart";
import * as _ from "lodash";
import { AdminDataProvider } from "../../lib/service/AdminDataProvider";
import sBox from "devextreme/ui/select_box";
import dBox from "devextreme/ui/date_box";
import { alert, confirm } from "devextreme/ui/dialog";
import DataGrid, {
  Column,
  Pager,
  Paging,
  LoadPanel,
} from "devextreme-react/data-grid";
import moment from "moment";
import { lang } from "../../lib/utils/language";
import { locale, loadMessages, formatMessage } from "devextreme/localization";
loadMessages(lang);
locale("" + localStorage.getItem("lang"));

// name을 제외하고 sessionStorage를 초기화 하는 함수
// function clearSessionStorageExceptName(): void {
//   // "name"에 해당하는 값을 가져오기
//   const nameValue = sessionStorage.getItem("name");

//   // sessionStorage를 비우기
//   sessionStorage.clear();

//   // 다시 "name" 값을 설정하기 (다른 데이터가 없다면 비워진 상태로 설정될 것입니다)
//   sessionStorage.setItem("name", nameValue || "");
// }

export const PatientDetailProps: FunctionComponent<IPatientDetailProps> = (
  props
) => {
  const history = useHistory();
  const common = new Common();
  const param: any = useParams();
  const [daterangeState, setDateRangeState] = useState("D");
  const [gameState, setGameState] = useState("");
  const [startMinDate, setStartMinState] = useState<Date | undefined>(
    undefined
  );
  const [startMaxDate, setStartMaxState] = useState<Date | undefined>(
    undefined
  );
  const [endMinDate, setEndMinState] = useState<Date | undefined>(undefined);
  const [endMaxDate, setEndMaxState] = useState<Date | undefined>(undefined);
  const [endDateBoxState, setEndDateBoxState] = useState(false);
  const [PatientInfo, setPatientInfo] = useState({} as any);
  const [List, setList] = useState({} as any);
  const [isDisplayPopupVisible, setDisplayPopupVisibility] = useState(false);
  const [NoC, setNoColumnState] = useState(true);
  const [MeasureC, setMeasureColumnState] = useState(true);
  const [GNC, setGNState] = useState(true);
  const [GLC, setGLState] = useState(true);
  const [GPC, setGPState] = useState(true);
  const [GTC, setGTState] = useState(true);
  const [APC, setAPState] = useState(true);
  const [ATC, setATState] = useState(true);
  const [isPopupVisible, setPopupVisibility] = useState(false);
  const [GameInfo, setGameInfo] = useState({} as any);
  const [BpmData, setbpmData] = useState({} as any);
  const [PointChartData, setPointChartData] = useState([] as any);
  const [TimeChartData, setTimeChartData] = useState([] as any);

  /* componentDidMount && componentDidUpdate */
  useEffect(() => {
    common.ResizePage();
    setEndDateBoxState(false);
    setStartMinState(moment("2023-01-01").toDate());
    setStartMaxState(moment("2050-12-31").toDate());
    setEndMinState(moment("2023-01-01").toDate());
    setEndMaxState(moment("2050-12-31").toDate());

    if (sessionStorage.getItem("name") !== null) {
      if (sessionStorage.getItem("p_startDate")) {
        const dateRangeState = sessionStorage.getItem("p_daterangeState");
        const gameState = sessionStorage.getItem("p_appId");
        const endDateBoxState = sessionStorage.getItem("p_endDateBoxState");
        setDateRangeState(dateRangeState !== null ? dateRangeState : "D");
        setGameState(gameState !== null ? gameState : "");
        setEndDateBoxState(
          endDateBoxState !== null ? JSON.parse(endDateBoxState) : ""
        );
        const adminService = new AdminDataProvider();
        const offset = 0;
        const limit = 5000;
        valToTag(
          "startDate",
          dBox,
          adminService.dateFormat(sessionStorage.getItem("p_startDate"))
        );
        valToTag(
          "endDate",
          dBox,
          adminService.dateFormat(sessionStorage.getItem("p_endDate"))
        );

        const json = {
          patientId: param.id,
          offset: offset,
          limit: limit,
          startDate: adminService.dateFormat(
            sessionStorage.getItem("p_startDate")
          ),
          endDate: adminService.dateFormat(sessionStorage.getItem("p_endDate")),
          appId: sessionStorage.getItem("p_appId"),
        };

        adminService
          .getRehabilitationPatientDetail(json)
          .then(async (e: any) => {
            console.log(e);

            let list = e.data.data.gameInfo;

            list.forEach((patient: { input_utc_dt: moment.MomentInput }) => {
              patient.input_utc_dt = moment(patient.input_utc_dt).format(
                "DD/MM/YYYY HH:mm:ss"
              );
            });

            setPatientInfo(e.data.data.patientInfo[0]);
            setList(list);
          });
        if (dateRangeState === "D") {
          setStartMinState(moment("2023-01-01").toDate());
          setStartMaxState(_dev("endDate", dBox).option("value"));
          setEndMinState(_dev("startDate", dBox).option("value"));
          setEndMaxState(moment("2050-12-31").toDate());
        }
      } else {
        btnSearch("");
        setStartMinState(moment("2023-01-01").toDate());
        setStartMaxState(_dev("endDate", dBox).option("value"));
        setEndMinState(_dev("startDate", dBox).option("value"));
        setEndMaxState(moment("2050-12-31").toDate());
      }
    } else {
      history.push("/auth/login");
    }
  }, [props]);

  // 날짜 범위 셀렉트 박스 항목
  const selectBoxData = [
    {
      id: 1,
      name: "Day",
      value: "D",
    },
    {
      id: 2,
      name: "Week",
      value: "W",
    },
    {
      id: 3,
      name: "Month",
      value: "M",
    },
  ];

  // game 셀렉트 박스 항목
  const selectBoxData2 = [
    {
      id: 0,
      name: "All",
      value: "",
    },
    {
      id: 1,
      name: "SmartROM",
      value: "SmartROM",
    },
    {
      id: 2,
      name: "Gates",
      value: "Gates",
    },
    {
      id: 3,
      name: "SmartClean",
      value: "SmartClean",
    },
    {
      id: 4,
      name: "SmartBubblePop",
      value: "SmartBubblePop",
    },
    {
      id: 5,
      name: "SmartSortAndPlace",
      value: "SmartSortAndPlace",
    },
    {
      id: 6,
      name: "SmartbArm",
      value: "SmartbArm",
    },
    {
      id: 7,
      name: "SmartPress",
      value: "SmartPress",
    },
    {
      id: 8,
      name: "Runner",
      value: "Runner",
    },
  ];

  // 날짜 범위 설정을 셀렉트 박스로 설정하였을때
  const setDate = () => {
    const dRangeVal = _dev("daterange", sBox).option("value");
    if (dRangeVal === "D") {
      setEndDateBoxState(false);
      valToTag("startDate", dBox, moment());
      valToTag("endDate", dBox, moment());
      setStartMinState(moment("2023-01-01").toDate());
      setStartMaxState(_dev("endDate", dBox).option("value"));
      setEndMinState(_dev("startDate", dBox).option("value"));
      setEndMaxState(moment("2050-12-31").toDate());
      setDateRangeState("D");
    } else if (dRangeVal === "W") {
      setEndDateBoxState(true);
      valToTag("startDate", dBox, moment().add(-7, "days"));
      valToTag("endDate", dBox, moment());
      setStartMinState(moment("2023-01-01").toDate());
      setStartMaxState(moment().add(-7, "days").toDate());
      setEndMinState(moment("2023-01-01").toDate());
      setDateRangeState("W");
    } else if (dRangeVal === "M") {
      setEndDateBoxState(true);
      valToTag("startDate", dBox, moment().startOf("month"));
      valToTag("endDate", dBox, moment().endOf("month"));
      setStartMinState(moment("2023-01-01").toDate());
      setStartMaxState(moment().toDate());
      setEndMinState(moment("2023-01-01").toDate());
      setDateRangeState("M");
    } else {
      setEndDateBoxState(false);
      setStartMinState(moment("2023-01-01").toDate());
      setStartMaxState(_dev("endDate", dBox).option("value"));
      setEndMinState(_dev("startDate", dBox).option("value"));
      setEndMaxState(moment("2050-12-31").toDate());
      setDateRangeState("D");
    }
  };

  const setGame = () => {
    const gameVal = _dev("game", sBox).option("value");
    if (gameVal === "") {
      setGameState("");
    } else if (gameVal === "SmartROM") {
      setGameState("SmartROM");
    } else if (gameVal === "Gates") {
      setGameState("Gates");
    } else if (gameVal === "SmartClean") {
      setGameState("SmartClean");
    } else if (gameVal === "SmartBubblePop") {
      setGameState("SmartBubblePop");
    } else if (gameVal === "SmartSortAndPlace") {
      setGameState("SmartSortAndPlace");
    } else if (gameVal === "SmartbArm") {
      setGameState("SmartbArm ");
    } else if (gameVal === "SmartPress") {
      setGameState("SmartPress");
    } else if (gameVal === "Runner") {
      setGameState("Runner");
    } else {
      setGameState("");
    }
  };

  // reset 버튼을 눌렀을때 함수
  const resetSearch = () => {
    setEndDateBoxState(false);
    setStartMinState(moment("2023-01-01").toDate());
    setStartMaxState(moment("2050-12-31").toDate());
    setEndMinState(moment("2023-01-01").toDate());
    setEndMaxState(moment("2050-12-31").toDate());
    setDateRangeState("D");
    valToTag("startDate", dBox, moment());
    valToTag("endDate", dBox, moment());
    setGameState("");
  };

  // datebox 태그의 value 값을 다른 value로 넣는 함수
  const valToTag = (e: any, box: any, value: any) => {
    const id = document.getElementById(e) as HTMLElement;
    const instance = box.getInstance(id);
    instance.option("value", value);
  };

  // 태그의 정보를 불러오는 함수
  const _dev = (e: any, d: any) => {
    const id = document.getElementById(e) as HTMLElement;
    return d.getInstance(id);
  };

  // startDate의 value값을 endDate의 min으로 설정하는 함수
  // const setMindate = () => {
  //   setMinState(_dev("startDate", dBox).option("value"));
  // };

  // startDate 선택시 endDate 값 변경
  const funcSetEndDate = () => {
    const dRangeVal = _dev("daterange", sBox).option("value");
    const startDate = _dev("startDate", dBox).option("value");
    if (dRangeVal === "W") {
      valToTag("endDate", dBox, moment(startDate).add(7, "days"));
    } else if (dRangeVal === "M") {
      valToTag("endDate", dBox, moment(startDate).endOf("month"));
    } else {
      setStartMinState(moment("2023-01-01").toDate());
      setStartMaxState(_dev("endDate", dBox).option("value"));
      setEndMinState(_dev("startDate", dBox).option("value"));
      setEndMaxState(moment("2050-12-31").toDate());
    }
  };

  // endDate 선택시 endDate 값 변경
  const funcSetStartDate = () => {
    const dRangeVal = _dev("daterange", sBox).option("value");
    if (dRangeVal === "D") {
      setStartMinState(moment("2023-01-01").toDate());
      setStartMaxState(_dev("endDate", dBox).option("value"));
      setEndMinState(_dev("startDate", dBox).option("value"));
      setEndMaxState(moment("2050-12-31").toDate());
    }
  };

  // 검색버튼 클릭 데이터 요청
  const btnSearch = async (d: any) => {
    const adminService = new AdminDataProvider();
    // const offset = _dev("offset", sBox).option("value");
    const offset = 0;
    // const limit = _dev("limit", tBox).option("value");
    const limit = 5000;
    const startDate = _dev("startDate", dBox).option("value");
    const endDate = _dev("endDate", dBox).option("value");
    const game = _dev("game", sBox).option("value");

    const json = {
      patientId: param.id,
      offset: offset,
      limit: limit,
      startDate: adminService.dateFormat(startDate),
      endDate: adminService.dateFormat(endDate),
      appId: game,
    };

    adminService.getRehabilitationPatientDetail(json).then(async (e: any) => {
      console.log(e);

      let list = e.data.data.gameInfo;

      list.forEach((patient: { input_utc_dt: moment.MomentInput }) => {
        patient.input_utc_dt = moment(patient.input_utc_dt).format(
          "DD/MM/YYYY HH:mm:ss"
        );
      });

      setPatientInfo(e.data.data.patientInfo[0]);
      setList(list);
    });
  };

  // 재활치료 게임 상세 데이터 요청
  const getGameDetail = async (e: any) => {
    const gameinfo = e.data;

    console.log(gameinfo);
    // Click 된 Row에서 가져온 데이터를 state에 저장
    setGameInfo(gameinfo);

    const adminService = new AdminDataProvider();
    const json = {
      patientId: param.id,
    };

    adminService.getRehabilitationGameDetail(json).then(async (e: any) => {
      console.log("detail: ", e);

      // 환자의 Average Point
      const averageRehabScore = Math.round(
        e.data.data.scoreMyAverage.average_score
      );

      // 환자의 Average of Top 10% Point
      const topRehabScore = Math.round(
        e.data.data.scoreTop10Percent.average_score
      );

      // point 차트 결과 JSON 생성
      const pointResultJson = [
        {
          chart: "Point",
          rehab_score: gameinfo.score,
          average: averageRehabScore,
          top: topRehabScore,
        },
      ];

      // 환자의 Average Time
      const averageRehabTime = Math.round(
        e.data.data.timeMyAverage.average_time
      );

      // 환자의 Average of Top 10% Time
      const topRehabTime = Math.round(
        e.data.data.timeTop10Percent.average_time
      );

      // point 차트 결과 JSON 생성
      const timeResultJson = [
        {
          chart: "Time",
          rehab_time: gameinfo.time,
          average: averageRehabTime,
          top: topRehabTime,
        },
      ];

      if (e.data.data.bpmInfo !== undefined) {
        setbpmData(e.data.data.bpmInfo);
      } else {
        setbpmData(undefined);
      }

      setPointChartData(pointResultJson);
      setTimeChartData(timeResultJson);
    });
  };

  // 컬럼 출력 설정 팝업 켜기/끄기
  const openDisplayPopup = (e: any) => {
    setDisplayPopupVisibility(!isDisplayPopupVisible);
  };

  // No 컬럼 출력 변경
  const changeNoCValue = (e: any) => {
    setNoColumnState(!NoC);
  };

  // 측정일자 컬럼 출력 변경
  const changeMeasureCValue = (e: any) => {
    setMeasureColumnState(!MeasureC);
  };

  // 게임이름 컬럼 출력 변경
  const changeGNCValue = (e: any) => {
    setGNState(!GNC);
  };

  // 게임레벨 컬럼 출력 변경
  const changeGLCValue = (e: any) => {
    setGLState(!GLC);
  };

  // 게임레벨 컬럼 출력 변경
  const changeGPCValue = (e: any) => {
    setGPState(!GPC);
  };

  // 게임시간 컬럼 출력 변경
  const changeGTCValue = (e: any) => {
    setGTState(!GTC);
  };

  // 평균 포인트 컬럼 출력 변경
  const changeAPCValue = (e: any) => {
    setAPState(!APC);
  };

  // 평균 시간 컬럼 출력 변경
  const changeATCValue = (e: any) => {
    setATState(!ATC);
  };

  // 환자 게임 상세 팝업 켜기/끄기
  const openGameInfoModal = () => {
    setPopupVisibility(!isPopupVisible);
  };

  const renderContent = () => {
    return (
      <div>
        <CheckBox
          text="No"
          defaultValue={NoC}
          onValueChanged={changeNoCValue}
        />
        <br />
        <CheckBox
          text="Measurement Date"
          defaultValue={MeasureC}
          onValueChanged={changeMeasureCValue}
          style={{ marginTop: "10px" }}
        />
        <br />
        <CheckBox
          text="Game Name"
          defaultValue={GNC}
          onValueChanged={changeGNCValue}
          style={{ marginTop: "10px" }}
        />
        <br />
        <CheckBox
          text="IsSuccess"
          defaultValue={GLC}
          onValueChanged={changeGLCValue}
          style={{ marginTop: "10px" }}
        />
        <br />
        <CheckBox
          text="Game Point"
          defaultValue={GPC}
          onValueChanged={changeGPCValue}
          style={{ marginTop: "10px" }}
        />
        <br />
        <CheckBox
          text="Game Time"
          defaultValue={GTC}
          onValueChanged={changeGTCValue}
          style={{ marginTop: "10px" }}
        />
        <br />
        <CheckBox
          text="Average Point"
          defaultValue={APC}
          onValueChanged={changeAPCValue}
          style={{ marginTop: "10px" }}
        />
        <br />
        <CheckBox
          text="Average Time"
          defaultValue={ATC}
          onValueChanged={changeATCValue}
          style={{ marginTop: "10px" }}
        />
        <br />
      </div>
    );
  };

  const renderContentChart = () => {
    return (
      <div style={{ height: "550px" }}>
        <div style={{ display: "flex", height: "100%" }}>
          <div
            style={{
              width: "29%",
              padding: "10px",
              border: "1px solid #0373c6",
            }}
          >
            <h2>
              Measurment Date:
              <br />
              {GameInfo.input_utc_dt}
              <br />
              <br />
              PatientId: {GameInfo.patient_id}
              <br />
              <br />
              Game Name: {GameInfo.r_app_id}
              <br />
              <br />
              IsSuccess:{" "}
              {GameInfo.is_success === 0 ? (
                <span style={{ color: "red" }}>Failure</span>
              ) : (
                <span style={{ color: "green" }}>Success</span>
              )}
              <br />
              <br />
              Point: {GameInfo.score}
              <br />
              <br />
              Time (sec): {GameInfo.time}
              <br />
              <br />
              Heart Rate (bpm):
              <br />
              {BpmData === "" || BpmData === undefined || BpmData === null
                ? "-"
                : BpmData.pulse +
                  ` (${moment(BpmData.input_utc_dt).format(
                    "DD/MM/YYYY HH:mm:ss"
                  )})`}
            </h2>
          </div>
          <div
            style={{
              display: "flex",
              width: "74%",
              marginLeft: "10px",
              padding: "10px",
              border: "1px solid red",
            }}
          >
            <div style={{ height: "95%", width: "49%" }}>
              <Chart
                title="Point"
                dataSource={PointChartData}
                id="chart"
                style={{ height: "100%", width: "100%" }}
              >
                <Tooltip enabled={true} />
                <CommonSeriesSettings
                  argumentField="chart"
                  type="bar"
                  hoverMode="allArgumentPoints"
                  selectionMode="allArgumentPoints"
                >
                  <Label visible={true}>
                    <Format type="fixedPoint" precision={0} />
                  </Label>
                </CommonSeriesSettings>

                <ValueAxis>
                  <Tick visible={false} />
                  <Label visible={false} />
                </ValueAxis>
                <Series
                  argumentField="chart"
                  valueField="rehab_score"
                  name={`${PatientInfo.last_name}`}
                />
                <Series
                  valueField="average"
                  name={`${PatientInfo.last_name} Average`}
                />
                <Series valueField="top" name="Average of Top 10%" />
                <Legend
                  verticalAlignment="bottom"
                  horizontalAlignment="center"
                ></Legend>

                {/* <Export enabled={true} /> */}
              </Chart>
            </div>
            <div style={{ height: "95%", width: "49%", marginLeft: "10px" }}>
              <Chart
                title="Time(second)"
                dataSource={TimeChartData}
                id="chart"
                style={{ height: "100%", width: "100%" }}
              >
                <CommonSeriesSettings
                  argumentField="chart"
                  type="bar"
                  hoverMode="allArgumentPoints"
                  selectionMode="allArgumentPoints"
                >
                  <Label visible={true}>
                    <Format type="fixedPoint" precision={0} />
                  </Label>
                </CommonSeriesSettings>

                <ValueAxis>
                  <Tick visible={false} />
                  <Label visible={false} />
                </ValueAxis>
                <Series
                  argumentField="chart"
                  valueField="rehab_time"
                  name={`${PatientInfo.last_name}`}
                />
                <Series
                  valueField="average"
                  name={`${PatientInfo.last_name} Average`}
                />
                <Series valueField="top" name="Average of Top 10%" />
                <Legend
                  verticalAlignment="bottom"
                  horizontalAlignment="center"
                ></Legend>

                {/* <Export enabled={true} /> */}
              </Chart>
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            width: "100%",
            marginTop: "10px",
            height: "110px",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Button width={200} height={50} onClick={openGameInfoModal}>
            Close
          </Button>
        </div>
      </div>
    );
  };

  return (
    <main id="content" className="content">
      <section className="sub-top">
        <Popup
          width={250}
          height={400}
          title="Column Chooser"
          visible={isDisplayPopupVisible}
          contentRender={renderContent}
          onHiding={openDisplayPopup}
        />
        <Popup
          width={1100}
          height={750}
          title="Game Info"
          visible={isPopupVisible}
          onHiding={openGameInfoModal}
          contentRender={renderContentChart}
        />
        <header>
          <div className="page-history">
            <ul>
              <li>
                <a href="/main">
                  <i className="fa fa-home" aria-hidden="true"></i>
                </a>
              </li>
              <li>
                <a href="/patient">
                  <span>PRS</span>
                </a>
              </li>
              <li className="current">
                <span>Patient Info</span>
              </li>
            </ul>
          </div>
        </header>

        <article
          className="reg-form insert"
          style={{ borderTop: "1px solid #0373c6" }}
        >
          <form action="" name="regForm">
            <fieldset>
              <article>
                <div
                  className="leftcont"
                  style={{
                    color: "#0373c6",
                    border: "1px solid #0373c6",
                    paddingLeft: "10px",
                    marginRight: "10px",
                  }}
                >
                  <i className="fa fa-user icon-img fa-2x"></i>&nbsp;
                  <strong style={{ fontSize: "30px" }}>Patient Info</strong>
                  <br />
                  <strong>PatientId: {param.id}</strong>
                  <br />
                  <strong>
                    PlatformId: {PatientInfo.source + PatientInfo.seq}
                  </strong>
                  <br />
                  <strong>
                    Age: {moment().diff(PatientInfo.dob, "years")}
                  </strong>
                  <br />
                  <strong>
                    Sex: {PatientInfo.gender === "M" ? "Male" : "Female"}
                  </strong>
                </div>

                <div className="cont">
                  <div className="contline">
                    <SelectBox
                      id="daterange"
                      dataSource={selectBoxData}
                      defaultValue={selectBoxData[0]}
                      displayExpr="name"
                      onValueChanged={setDate}
                      valueExpr="value"
                      value={daterangeState}
                      style={{ width: "150px", marginRight: "5px" }}
                    ></SelectBox>
                    <DateBox
                      id="startDate"
                      className="prs-datebox"
                      defaultValue={moment()}
                      displayFormat={"yyyy-MM-dd"}
                      onValueChanged={funcSetEndDate}
                      min={startMinDate}
                      max={startMaxDate}
                    ></DateBox>
                    <div style={{ paddingTop: "4px" }}>&#126;</div>
                    <DateBox
                      id="endDate"
                      className="prs-datebox"
                      defaultValue={moment()}
                      displayFormat={"yyyy-MM-dd"}
                      disabled={endDateBoxState}
                      onValueChanged={funcSetStartDate}
                      min={endMinDate}
                      max={endMaxDate}
                    ></DateBox>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      marginTop: "10px",
                    }}
                  >
                    <SelectBox
                      id="game"
                      dataSource={selectBoxData2}
                      defaultValue=""
                      displayExpr="name"
                      onValueChanged={setGame}
                      valueExpr="value"
                      value={gameState}
                      placeholder="Game"
                      style={{ width: "710px", marginRight: "10px" }}
                    ></SelectBox>
                    <Button
                      style={{ marginRight: "10px" }}
                      text="(R)"
                      type="normal"
                      onClick={resetSearch}
                      // stylingMode="contained"
                      // onClick={btnRst}
                    />
                    <Button
                      width={50}
                      icon="fa fa-search"
                      type="normal"
                      // stylingMode="contained"
                      onClick={btnSearch}
                    />
                  </div>
                </div>
              </article>
            </fieldset>
          </form>
        </article>
      </section>

      <section style={{ padding: "10px" }}>
        <header
          style={{
            display: "flex",
            width: "100%",
            marginBottom: "10px",
            marginTop: "10px",
          }}
        >
          <Button
            style={{ marginLeft: "auto" }}
            text="Display"
            onClick={openDisplayPopup}
          />
        </header>

        <section className="sub-cont__wrap">
          <article>
            <div className="tbl-wrap">
              <DataGrid
                id="gridCont1"
                dataSource={List}
                columnHidingEnabled={true}
                noDataText="No data"
                selection={{ mode: "single" }}
                hoverStateEnabled={true}
                allowColumnResizing={false}
                className="type-ct"
                onRowClick={(e) => {
                  openGameInfoModal();
                  getGameDetail(e);
                }}
                showBorders
              >
                <LoadPanel enabled />
                <Column
                  caption="No"
                  width={50}
                  // cellRender={(e) => {
                  //   return e.row.rowIndex + 1;
                  // }}
                  cellRender={(e) => {
                    const pageIndex = e.component.pageIndex();
                    const pageSize = e.component.pageSize();
                    const dataIndex = e.row.dataIndex;
                    const calculatedNo = pageIndex * pageSize + dataIndex + 1;
                    return calculatedNo;
                  }}
                  visible={NoC}
                />
                <Column
                  dataField="input_utc_dt"
                  caption="Measurment Date"
                  format="dd / MM / yyyy"
                  visible={MeasureC}
                />
                <Column
                  width={150}
                  dataField="r_app_id"
                  caption="Game Name"
                  visible={GNC}
                />
                <Column
                  width={150}
                  dataField="is_success"
                  caption="IsSuccess"
                  visible={GLC}
                  cellRender={(e) => {
                    return e.data.is_success === 0 ? (
                      <span style={{ color: "red", fontWeight: "bold" }}>
                        Failure
                      </span>
                    ) : e.data.is_success === 1 ? (
                      <span style={{ color: "green", fontWeight: "bold" }}>
                        Success
                      </span>
                    ) : (
                      <span></span>
                    );
                  }}
                />
                <Column
                  width={150}
                  dataField="score"
                  caption="Game Point"
                  visible={GPC}
                />
                <Column
                  width={150}
                  dataField="time"
                  caption="Game Time"
                  visible={GTC}
                />
                <Column
                  width={150}
                  dataField="avg_score"
                  caption="Average Point"
                  visible={APC}
                />
                <Column
                  width={150}
                  dataField="avg_time"
                  caption="Average Time"
                  visible={ATC}
                />
                <Paging defaultPageSize={10} />
                <Pager
                  visible={true}
                  allowedPageSizes={[10, 20]}
                  displayMode={"full"}
                  showPageSizeSelector={true}
                  showInfo={true}
                  showNavigationButtons={true}
                />
              </DataGrid>
            </div>
          </article>
        </section>
      </section>
    </main>
  );
};

export default PatientDetailProps;
